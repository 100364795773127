import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// CUSTOM COMPONENTS
import MatTableHead from './MatTableHead';

// CUSTOM SCSS
import './MatTable.scss';
import '../../scss/shared/Flag.scss';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class MatTable extends PureComponent {
  state = {
    order: 'asc',
    orderBy: '',
    selected: new Map([]),
    heads: [],
    subheads: [],
    data: [],
    page: 0,
    rowsPerPage: 10
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const oldSelected = this.state.selected;
    const newSelected = new Map([]);
    const value = oldSelected.get(id);
    if (!value) {
      newSelected.set(id, true);
    }
    this.setState({ selected: newSelected });
    this.props.onFilterUpdate(this.props.data.id, id);
  };

  handleChangePage = (event, page) => {
    // If searchResults type & next page is a new one, load data from API
    const newPage = page * this.state.rowsPerPage >= this.state.start;
    if (this.props.data.id === 'searchResults' && newPage) {
      this.props.onNextSearchPage();
    }

    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  cellClick = (label, item) => {
    if (label === 'Dataset') {
      this.props.onCellClicked(item.url);
    }
  };

  render() {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{this.props.title}</h5>
            <div className="subhead">{this.props.subTitle}</div>
          </div>
          <>
            {this.props.data.length > 0 && (
              <>
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <MatTableHead
                      numSelected={
                        [...this.state.selected].filter(el => el[1]).length
                      }
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={this.props.data.length}
                      heads={this.props.heads}
                      subheads={this.state.subheads}
                      checkBox={this.props.checkBox}
                    />
                    {this.state.subhead && (
                      <MatTableHead
                        numSelected={
                          [...this.state.selected].filter(el => el[1]).length
                        }
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        rowCount={this.props.data.length}
                        heads={this.state.subheads}
                        checkBox={this.props.checkBox}
                      />
                    )}
                    <TableBody>
                      {this.props.data
                        .sort(getSorting(this.state.order, this.state.orderBy))
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map(d => {
                          const isSelected = this.isSelected(d.id);
                          return (
                            <TableRow
                              className="material-table__row"
                              role="checkbox"
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={d.id}
                              selected={isSelected}
                              hover={false}
                            >
                              {this.props.heads.map((col, index) => {
                                return (
                                  <TableCell
                                    key={col.id + '-' + d.id}
                                    className="material-table__cell material-table__cell-right"
                                  >
                                    <div
                                      style={{
                                        textAlign: col.textAlign
                                          ? col.textAlign
                                          : 'left'
                                      }}
                                    >
                                      {d[col.id]}
                                    </div>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={
                    this.state.total ? this.state.total : this.props.data.length
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[]}
                  dir="ltr"
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true
                  }}
                />
              </>
            )}
          </>
        </CardBody>
      </Card>
    );
  }
}
