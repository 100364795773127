/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { compose, withProps, withStateHandlers, lifecycle } from 'recompose';

import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker
} from 'react-google-maps';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const _ = require('lodash');

const MapWithAMarker = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBN63jlOf5AOckC6cg9KafHCBPwAj2GvJo&v=3.' +
      'exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="map" style={{ height: '460px' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withStateHandlers(
    () => ({
      isOpen: true
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen
      })
    }
  ),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        bounds: null,
        center: {
          lat: 41.9,
          lng: -87.624
        },
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter()
          });
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          console.log('places ', places);

          const google = (window.google = window.google ? window.google : {});

          const bounds = new google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location
          }));
          const nextCenter = _.get(
            nextMarkers,
            '0.position',
            this.state.center
          );

          this.setState({
            center: nextCenter,
            markers: nextMarkers
          });
          // refs.map.fitBounds(bounds);
        }
      });
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  console.log('refreshing PoiMap');
  console.log('position: ', props.position);

  return (
    <GoogleMap
      defaultZoom={13}
      {...(props.changeMapCenter
        ? {
            center: props.position
          }
        : {})}
      // center={props.position}
      defaultCenter={props.position}
      onClick={props.onMapClick}
    >
      {props.markers.map((marker, index) => (
        <Marker key={index} position={marker.position} />
      ))}
      <Marker position={props.position}></Marker>
    </GoogleMap>
  );
});

const PoiMap = ({ onMapClick, position, changeMapCenter }) => (
  <MapWithAMarker
    onMapClick={onMapClick}
    changeMapCenter={changeMapCenter}
    position={position}
  />
);

PoiMap.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation('common')(PoiMap);
