import authenticationApi from '../../services/authentication/authenticationApi';
import dataApi from '../../services/data/dataApi';
import authStorage from '../../services/authentication/authStorage';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const FETCHING_API = 'FETCHING_API';
export const RESET_REDUX = 'RESET_REDUX';

/** Error message for network down */
const ERROR = 'Something went wrong. Please try later!';

export const set_token = token => {
  return {
    type: SET_TOKEN,
    payload: token
  };
};

export const set_user = payload => {
  return {
    type: SET_USER,
    payload: payload
  };
};

export const log_in_error = message => {
  return {
    type: LOG_IN_ERROR,
    payload: message
  };
};

export const fetch_api = loading => {
  return {
    type: FETCHING_API,
    payload: loading
  };
};

export const reset_redux = () => {
  return {
    type: RESET_REDUX
  };
};

export const resetRedux = () => {
  return dispatch => {
    dispatch(reset_redux());
  };
};

/**
 * Calls api with credentials and set tokens in successful login
 * @param {*} user
 */
export const login = user => {
  return async dispatch => {
    dispatch(fetch_api(true));
    try {
      const response = await authenticationApi.login(user);
      if (response && response.status === 200) {
        authStorage.setAuth('auth', response.data);
        console.log('response', response);
        setUser();
        dispatch(set_token(response.data));
      } else {
        if (response.data.message === 'WRONG_CREDENTIALS') {
          dispatch(
            log_in_error('Your credentials are wrong. Please check the form!')
          );
        } else {
          dispatch(log_in_error(ERROR));
        }
      }
    } catch (error) {
      dispatch(log_in_error(ERROR));
    }
    dispatch(fetch_api(false));
  };
};

/**
 * Set token to null value
 * @param {*} token
 */
export const logout = token => {
  return dispatch => {
    dispatch(set_token(null));
  };
};

/**
 * Calls api with credentials and set tokens in successful registration
 * @param {*} user
 */
export const register = user => {
  return async dispatch => {
    dispatch(fetch_api(true));
    try {
      const response = await authenticationApi.register(user);
      if (response && response.status === 200) {
        authStorage.setAuth('auth', response.data);
        setUser();
        dispatch(set_token(response.data));
      } else {
        if (response.data.message === 'USERNAME_EXISTS') {
          dispatch(
            log_in_error(
              'Your username is already taken. Please choose another one!'
            )
          );
        } else if (response.data.message === 'EMAIL_EXISTS') {
          dispatch(
            log_in_error(
              'Your email is already taken. Please choose another one!'
            )
          );
        } else {
          dispatch(log_in_error(ERROR));
        }
      }
    } catch (error) {
      dispatch(log_in_error(ERROR));
    }
    dispatch(fetch_api(false));
  };
};

/**
 * Calls api to set user
 */
export const setUser = () => {
  return async dispatch => {
    try {
      const response = await dataApi.fetch('user', null);
      if (response && response.status === 200) {
        dispatch(set_user(response.data));
      } else {
        dispatch(log_in_error(ERROR));
        authStorage.removeAuth('auth');
      }
    } catch (error) {
      dispatch(log_in_error(ERROR));
    }
  };
};
