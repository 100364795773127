import dataApi from '../../../services/data/dataApi';

// CUSTOM DATA
import { LOCALE } from '../../../shared/utils/utils';

export const FETCH_AMOUNT_DATA = 'FETCH_AMOUNT_DATA';
export const FETCH_POIS_SUCCESSFUL = 'FETCH_POIS_SUCCESSFUL';
export const POIS_API_ERROR = 'POIS_API_ERROR';
export const SAVE_POI_SUCCESSFUL = 'SAVE_POI_SUCCESSFUL';
export const DELETE_POI_SUCCESSFUL = 'DELETE_POI_SUCCESSFUL';
export const POIS_LOADING = 'POIS_LOADING';
export const TOGGLE_POI_INFO_WINDOW = 'TOGGLE_POI_INFO_WINDOW';

function poisLoading(payload) {
  console.log('calling POIS_LOADING', payload);
  return {
    type: POIS_LOADING,
    payload,
  };
}

function setData(payload) {
  console.log('calling setData', payload);
  return {
    type: FETCH_POIS_SUCCESSFUL,
    payload,
  };
}

function savePoi(payload) {
  console.log('calling savePoi', payload);
  return {
    type: SAVE_POI_SUCCESSFUL,
    payload,
  };
}

function deletePoi() {
  console.log('calling deletePoi');
  return {
    type: DELETE_POI_SUCCESSFUL,
  };
}

export function togglePoiInfoWindow(payload) {
  console.log('calling togglePoiInfoWindow', payload);
  return {
    type: TOGGLE_POI_INFO_WINDOW,
    payload,
  };
}

export function setError(payload) {
  return {
    type: POIS_API_ERROR,
    payload,
  };
}

export function getPois(payload) {
  return async (dispatch) => {
    dispatch(poisLoading());
    try {
      const params = { ...payload, locale: LOCALE };
      const response = await dataApi.fetch('pois', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = setData(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function clearPois() {
  return async (dispatch) => {
    dispatch(setData([]));
  };
}

export function filterPois(payload) {
  return async (dispatch) => {
    dispatch(poisLoading());
    try {
      const params = payload;
      const response = await dataApi.fetch('filterPois', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        if (response.data.length > 0) {
          dispatch(setData(response.data));
        } else {
          dispatch(setError('No data found for this search term'));
        }
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function savePoiToDB(payload) {
  console.log('calling savePoiToDB...');

  return async (dispatch) => {
    try {
      dispatch(poisLoading());
      let response;
      if (payload.update) {
        response = await dataApi.update('pois', payload.poi);
      } else {
        response = await dataApi.post('pois', payload.poi);
      }
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = savePoi(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function deletePoiFromDB(payload) {
  console.log('calling deletePoiFromDB...');

  return async (dispatch) => {
    try {
      dispatch(poisLoading());

      const response = await dataApi.remove('pois', payload);
      if (response.status === 204) {
        console.log('deletePoiFromDB response:', response);
        let action = deletePoi();
        console.log('deletePoiFromDB action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function fetchAdditionalPois(payload) {
  return async (dispatch) => {
    dispatch(poisLoading());
    try {
      const params = { ...payload, locale: LOCALE };
      const response = await dataApi.fetch('pois', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = setData(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}
