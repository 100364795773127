/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';
import { Button } from 'reactstrap';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Polyline,
  Marker
} from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// ICONS
import CloseIcon from 'mdi-react/CloseIcon';

// CUSTOM SCSS
import './GoogleMap.scss';

const {
  MarkerWithLabel
} = require('react-google-maps/lib/components/addons/MarkerWithLabel');
let redMarker = new window.google.maps.MarkerImage(
  require('../../shared/img/markers/marker-red.png'),
  null /* size is determined at runtime */,
  null /* origin is 0,0 */,
  null /* anchor is bottom center of the scaled image */,
  new window.google.maps.Size(40, 40)
);
let blueMarker = new window.google.maps.MarkerImage(
  require('../../shared/img/markers/marker-blue.png'),
  null /* size is determined at runtime */,
  null /* origin is 0,0 */,
  null /* anchor is bottom center of the scaled image */,
  new window.google.maps.Size(40, 40)
);

const google = (window.google = window.google ? window.google : {});
const MapWithAMarker = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBN63jlOf5AOckC6cg9KafHCBPwAj2GvJo&v=3.' +
      'exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="map" style={{ height: '460px' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withStateHandlers(
    () => ({
      isOpen: true
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen
      })
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => {
  console.log('MAP: polyline length:', props.pathCoordinates.length);
  console.log('MAP: polyline start:', props.pathCoordinates[0]);
  console.log('MAP: waypoints length', props.waypoints.length);
  console.log('MAP change center: ', props.changeCenter);

  return (
    <GoogleMap
      defaultZoom={13}
      {...(props.changeCenter && props.pathCoordinates[0]
        ? {
            center: {
              lat: props.pathCoordinates[0].lat,
              lng: props.pathCoordinates[0].lng
            }
          }
        : {})}
      // center={{
      //   lat: props.pathCoordinates[0].lat,
      //   lng: props.pathCoordinates[0].lng
      // }}
      defaultCenter={props.mapCenter}
    >
      <Polyline
        path={props.pathCoordinates}
        geodesic={true}
        options={{
          strokeColor: '#ff2527',
          strokeOpacity: 0.75,
          strokeWeight: 2,
          icons: [
            {
              // icon: props.lineSymbol,
              offset: '0',
              repeat: '20px'
            }
          ]
        }}
      />
      {/* start marker  */}
      {/* <MarkerWithLabel
        position={{
          lat: props.pathCoordinates[0].lat,
          lng: props.pathCoordinates[0].lng
        }}
        labelAnchor={new google.maps.Point(10, 40)}
        labelStyle={{
          backgroundColor: 'yellow',
          color: 'black',
          fontSize: '12px',
          padding: '2px'
        }}
      >
        <div>START</div>
      </MarkerWithLabel> */}
      {/* all steps of the tour */}
      {props.waypoints.map((poi, index) => {
        let poiCoordinates = { lat: poi.latitude, lng: poi.longitude };
        return (
          <MarkerWithLabel
            key={poi.id}
            icon={redMarker}
            position={poiCoordinates}
            labelAnchor={new google.maps.Point(3, 29)}
            labelStyle={{
              color: '#eb5b41',
              fontSize: '11px',
              fontWeight: '700'
            }}
          >
            <div>{index + 1}</div>
          </MarkerWithLabel>
        );
      })}

      {props.showAllPois &&
        props.allPois &&
        props.allPois.map((poi, index) => {
          let poiCoordinates = { lat: poi.latitude, lng: poi.longitude };
          let boundItemClick = props.onClickPoi.bind(this, poi);
          let boundAddItemToTemplate = props.addToTemplate.bind(this, poi);
          return (
            <Marker
              key={poi.id}
              icon={blueMarker}
              position={poiCoordinates}
              onClick={() => boundItemClick(poi)}
            >
              {poi.isOpen && (
                <InfoBox
                  options={{
                    pane: 'mapPane',
                    pixelOffset: new google.maps.Size(-40, -58),
                    closeBoxURL: ``,
                    enableEventPropagation: true
                  }}
                >
                  <div className="map__marker-label">
                    <div className="map__marker-label-content">
                      <div
                        className="map__maker-label-close"
                        onClick={() => boundItemClick(poi)}
                      >
                        <CloseIcon />
                      </div>
                      <div className="map__marker-label-title">
                        {poi.nameTranslations.en}
                      </div>
                      <div className="button_container d-flex justify-content-end">
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                          outline={true}
                          onClick={() => boundAddItemToTemplate(poi)}
                        >
                          Add to Tour
                        </Button>
                      </div>
                    </div>
                  </div>
                </InfoBox>
              )}
            </Marker>

            // <MarkerWithLabel
            //   key={poi.id}
            //   position={poiCoordinates}
            //   labelAnchor={new google.maps.Point(10, 40)}
            //   labelStyle={{
            //     backgroundColor: 'blue',
            //     color: 'black',
            //     fontSize: '12px',
            //     padding: '2px'
            //   }}
            // >
            //   <div>
            //     {index + 1}. {poi.name}
            //   </div>
            // </MarkerWithLabel>
          );
        })}

      {/* finish marker  */}

      {/* <MarkerWithLabel
        position={{
          lat: props.pathCoordinates[lastPosition].lat,
          lng: props.pathCoordinates[lastPosition].lng
        }}
        labelAnchor={new google.maps.Point(10, 40)}
        labelStyle={{
          backgroundColor: 'yellow',
          color: 'black',
          fontSize: '12px',
          padding: '2px'
        }}
      >
        <div>FINISH</div>
      </MarkerWithLabel> */}
    </GoogleMap>
  );
});

const BasicMap = ({
  t,
  showMarker,
  mapCenter,
  pathCoordinates,
  waypoints,
  allPois,
  showAllPois,
  onClickPoi,
  addToTemplate,
  changeCenter
}) => (
  <MapWithAMarker
    mapCenter={mapCenter}
    isMarkerShown={showMarker}
    pathCoordinates={pathCoordinates}
    waypoints={waypoints}
    allPois={allPois}
    showAllPois={showAllPois}
    onClickPoi={onClickPoi}
    addToTemplate={addToTemplate}
    changeCenter={changeCenter}
  />
);

BasicMap.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation('common')(BasicMap);
