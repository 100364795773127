import React from 'react';
import classNames from 'classnames';

// ICONS
import CloseIcon from 'mdi-react/CloseIcon';

// CUSTOM SCSS
import './Customizer.scss';

const Customizer = props => {
  const customizerClass = classNames({
    customizer__wrap: true,
    'customizer__wrap--open': props.open
  });

  return (
    <div className={`customizer ${props.className}`}>
      <div className={customizerClass} hidden={!props.open}>
        <div className="customizer__title-wrap d-flex align-items-center">
          <h3>{props.title}</h3>
          <button
            className="customizer__close-btn"
            type="button"
            onClick={props.handleOpen}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="customizer__caption">{props.caption}</div>
        <div className="customizer__body">{props.children}</div>
      </div>
    </div>
  );
};

export default Customizer;
