import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';

// REDUX
import { connect } from 'react-redux';
import {
  getStatistics,
  updateDuration
} from '../../redux/actions/statistics/statisticsActions';

// CUSTOM COMPONENTS
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import MatTable from '../../components/MatTable/MatTable';
import SelectField from '../../components/SelectField/SelectField';

// CUSTOM DATA
import { CATEGORΥ_NAMES, DURATION } from '../../shared/utils/utils';

// CUSTOM SCSS
import './Statistics.scss';

class Statistics extends PureComponent {
  state = {
    poisUsage: [],
    poisUsageHeads: [
      {
        id: 'name',
        disablePadding: false,
        label: 'Poi title',
        fullWidth: true
      },
      {
        id: 'tours',
        disablePadding: false,
        label: 'Tour instances',
        fullWidth: false,
        textAlign: 'center'
      },
      {
        id: 'templates',
        disablePadding: false,
        label: 'Template instances',
        fullWidth: false,
        textAlign: 'center'
      }
    ],
    categoryUsage: [],
    categoryUsageHeads: [
      {
        id: 'name',
        disablePadding: false,
        label: 'Category',
        fullWidth: true
      },
      {
        id: 'pois',
        disablePadding: false,
        label: 'Poi instances',
        fullWidth: false,
        textAlign: 'center'
      }
    ],
    tourTemplateUsage: [],
    tourTemplateUsageHeads: [
      {
        id: 'name',
        disablePadding: false,
        label: 'Template title',
        fullWidth: true
      },
      {
        id: 'tours',
        disablePadding: false,
        label: 'Tour instances',
        fullWidth: false,
        textAlign: 'center'
      }
    ]
  };

  componentDidMount() {
    this.props.getStatistics(this.props.statistics.duration);
  }

  componentDidUpdate(prevProps) {
    // Create pois usage data
    if (
      this.props.statistics.data.poisUsage &&
      JSON.stringify(this.props.statistics.data.poisUsage) !==
        JSON.stringify(prevProps.statistics.data.poisUsage)
    ) {
      const poisUsage = [];
      Object.keys(this.props.statistics.data.poisUsage).forEach(
        (key, index) => {
          const tempPoi = {
            id: 'poi-' + index,
            name: key,
            tours: this.props.statistics.data.poisUsage[key],
            templates: this.props.statistics.data.poisUsage[key]
          };
          poisUsage.push(tempPoi);
        }
      );
      this.setState({ poisUsage });
    }

    // Create categories usage data
    if (
      this.props.statistics.data.categoryUsage &&
      JSON.stringify(this.props.statistics.data.categoryUsage) !==
        JSON.stringify(prevProps.statistics.data.categoryUsage)
    ) {
      const categoryUsage = [];
      Object.keys(this.props.statistics.data.categoryUsage).forEach(
        (key, index) => {
          const tempCategory = {
            id: 'category-' + index,
            name: CATEGORΥ_NAMES[key],
            pois: this.props.statistics.data.categoryUsage[key]
          };
          categoryUsage.push(tempCategory);
        }
      );
      this.setState({ categoryUsage });
    }

    // Create templates usage data
    if (
      this.props.statistics.data.tourTemplateUsage &&
      JSON.stringify(this.props.statistics.data.tourTemplateUsage) !==
        JSON.stringify(prevProps.statistics.data.tourTemplateUsage)
    ) {
      const tourTemplateUsage = [];
      Object.keys(this.props.statistics.data.tourTemplateUsage).forEach(
        (key, index) => {
          const tempTemplate = {
            id: 'template-' + index,
            name: key,
            tours: this.props.statistics.data.tourTemplateUsage[key]
          };
          tourTemplateUsage.push(tempTemplate);
        }
      );
      this.setState({ tourTemplateUsage });
    }
  }

  changeDuration(event) {
    this.props.updateDuration(event.target.value);
  }

  render() {
    return (
      <Container className="dashboard statistics_dashboard">
        <Row>
          <Col>
            <div className="page_title_container">
              <h3 className="page_title">Statistics</h3>
              <h4 className="page_subtitle">
                In this section you can access statistical data about your pois
                and tours.
              </h4>
            </div>

            <div className="duration_container d-flex justify-content-end">
              <SelectField
                options={DURATION}
                value={this.props.statistics.duration}
                label="Statistical analysis period"
                onChange={event => this.changeDuration(event)}
                editable={true}
              />
            </div>

            <Row className="statistics_container">
              <Loader
                loading={this.props.statistics.fetching}
                className="full_page"
              />
              {!this.props.statistics.fetching && (
                <Scrollbars
                  renderTrackHorizontal={props => (
                    <div {...props} className="track-horizontal" />
                  )}
                >
                  <Col>
                    <Row>
                      <div className="total_card d-flex justify-content-center align-items-end">
                        <div className="number">
                          {this.props.statistics.data.totalTours}
                        </div>
                        <div>Total tour</div>
                      </div>
                      <div className="total_card d-flex justify-content-center align-items-end">
                        <div className="number">
                          {this.props.statistics.data.totalTemplates}
                        </div>
                        <div>Total templates</div>
                      </div>
                      <div className="total_card d-flex justify-content-center align-items-end">
                        <div className="number">
                          {this.props.statistics.data.totalUsers}
                        </div>
                        <div>Total users</div>
                      </div>
                    </Row>
                    {this.props.statistics.data.poisUsage && (
                      <Row>
                        <Col>
                          <MatTable
                            data={this.state.poisUsage}
                            heads={this.state.poisUsageHeads}
                            title="Pois Usage"
                            subTitle="Review how many times a poi is used in tours and templates"
                          ></MatTable>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      {this.props.statistics.data.categoryUsage && (
                        <Col xs={6}>
                          <MatTable
                            data={this.state.categoryUsage}
                            heads={this.state.categoryUsageHeads}
                            title="Categories Usage"
                            subTitle="Review how many times a category is used for pois"
                          ></MatTable>
                        </Col>
                      )}
                      {this.props.statistics.data.tourTemplateUsage && (
                        <Col xs={6}>
                          <MatTable
                            data={this.state.tourTemplateUsage}
                            heads={this.state.tourTemplateUsageHeads}
                            title="Templates Usage"
                            subTitle="Review how many times a template is used to create a tour"
                          ></MatTable>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Scrollbars>
              )}
            </Row>
          </Col>
        </Row>
        <Error
          error={this.props.statistics.error}
          page={true}
          onErrorClose={() => this.clearError()}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    statistics: state.statistics
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStatistics: payload => dispatch(getStatistics(payload)),
    updateDuration: payload => dispatch(updateDuration(payload))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Statistics)
);
