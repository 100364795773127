import React from 'react';
import PropTypes from 'prop-types';

// CUSTOM SCSS
import './Message.scss';

const Message = ({ message, page, onMessageClose }) => {
  return (
    <>
      {message && (
        <div className={`message-container ${page ? ' page-message' : ''}`}>
          {message}
          {onMessageClose && (
            <button
              className="message_btn"
              type="button"
              onClick={onMessageClose}
            >
              I understand
            </button>
          )}
        </div>
      )}
    </>
  );
};

Message.propTypes = {
  message: PropTypes.string
};

Message.defaultProps = {
  message: ''
};

export default Message;
