import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { RTLProps } from '../../shared/prop-types/ReducerProps';

class MatTableHead extends PureComponent {
  state = {
    heads: []
  };

  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired
  };

  render() {
    const { order, orderBy, rtl } = this.props;

    return (
      <TableHead>
        <TableRow>
          {this.props.heads.map(
            (col, index) => (
              <TableCell
                className="material-table__cell material-table__cell--sort material-table__cell-right"
                key={col.id}
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={col.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === col.id ? order : false}
                style={{ width: col.fullWidth ? '100%' : col.width + ' px' }}
              >
                <div
                  style={{
                    width: col.width + ' px',
                    textAlign: col.textAlign ? col.textAlign : 'left'
                  }}
                >
                  {col.label}
                </div>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl
}))(MatTableHead);
