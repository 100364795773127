import dataApi from '../../../services/data/dataApi';

// CUSTOM DATA
import { LOCALE } from '../../../shared/utils/utils';

export const FETCH_TOUR_TEMPLATES_SUCCESSFUL =
  'FETCH_TOUR_TEMPLATES_SUCCESSFUL';
export const TOUR_TEMPLATES_ERROR = 'TOUR_TEMPLATES_ERROR';
export const ADD_POI_TO_TEMPLATE = 'ADD_POI_TO_TEMPLATE';
export const FETCH_TOUR_TEMPLATE = 'FETCH_TOUR_TEMPLATE';
export const FETCH_TOUR_TEMPLATE_SUCCESSFUL = 'FETCH_TOUR_TEMPLATE_SUCCESSFUL';
export const RECALCULATE_TOUR_SUCCESSFUL = 'RECALCULATE_TOUR_SUCCESSFUL';
export const REMOVE_POI_FROM_TEMPLATE = 'REMOVE_POI_FROM_TEMPLATE';
export const REORDER_TOUR_POIS_SUCCESSFUL = 'REORDER_TOUR_POIS_SUCCESSFUL';
export const SAVE_TOUR_TEMPLATE_SUCCESSFUL = 'SAVE_TOUR_TEMPLATE_SUCCESSFUL';
export const CHANGE_TRANSPORTATION_TYPE = 'CHANGE_TRANSPORTATION_TYPE';
export const CHANGE_FLAG_INACTIVE = 'CHANGE_FLAG_INACTIVE';
export const NEW_TEMPLATE_CREATED = 'NEW_TEMPLATE_CREATED';
export const TOUR_LOADING = 'LOADING';

function toursLoading(payload) {
  console.log('calling TOUR_LOADING', payload);
  return {
    type: TOUR_LOADING,
    payload
  };
}

export function removePoiFromTemplate(payload) {
  console.log('calling remove Poi from template', payload);
  return {
    type: REMOVE_POI_FROM_TEMPLATE,
    payload
  };
}

export function changeTransportationType(payload) {
  console.log('received changeTransportationType: ', payload);
  return {
    type: CHANGE_TRANSPORTATION_TYPE,
    payload
  };
}

export function changeFlagInactive(payload) {
  console.log('received CHANGE_FLAG_INACTIVE: ', payload);
  return {
    type: CHANGE_FLAG_INACTIVE,
    payload
  };
}

export function addPoiToTemplate(payload) {
  console.log('received item to add to template: ', payload);
  return {
    type: ADD_POI_TO_TEMPLATE,
    payload
  };
}

export function updateTour(payload) {
  console.log('calling updateTour', payload);
  return {
    type: RECALCULATE_TOUR_SUCCESSFUL,
    payload
  };
}

export function reorderPois() {
  console.log('calling REORDER_TOUR_POIS_SUCCESSFUL');
  return {
    type: REORDER_TOUR_POIS_SUCCESSFUL
  };
}

function setLoader(payload) {
  console.log('calling setData', payload);
  return {
    type: FETCH_TOUR_TEMPLATE,
    payload
  };
}

export function saveTemplate(payload) {
  console.log('calling remove Poi from template', payload);
  return {
    type: SAVE_TOUR_TEMPLATE_SUCCESSFUL
  };
}

export function createNewTemplate(payload) {
  console.log('sending action NEW_TEMPLATE_CREATED', payload);
  return {
    type: NEW_TEMPLATE_CREATED,
    payload
  };
}

function setData(payload) {
  console.log('calling setData', payload);
  return {
    type: FETCH_TOUR_TEMPLATES_SUCCESSFUL,
    payload
  };
}

export function setError(payload) {
  return {
    type: TOUR_TEMPLATES_ERROR,
    payload
  };
}

export function selectTemplate(payload) {
  console.log('selected template', payload);

  return {
    type: FETCH_TOUR_TEMPLATE_SUCCESSFUL,
    payload
  };
}

export function recalculateTour(payload) {
  return async dispatch => {
    try {
      //set as start and end the first and last endpoints

      let tour = payload.tour;
      let reordering = payload.reordering;

      console.log('recalculateTourWithReorderingOption', tour, reordering);

      let totalWaypoints = tour.waypointList ? tour.waypointList.length : 0;

      if (totalWaypoints < 2) {
        dispatch(setError('You cannot have less than 2 POIs in a tour!'));
      }

      let firstWaypoint = tour.waypointList[0];
      let lastWaypoint = tour.waypointList[totalWaypoints - 1];
      tour.start = firstWaypoint;
      tour.end = lastWaypoint;
      let dateInMilis = new Date().getTime;
      tour.startingDate = Math.floor(dateInMilis / 1000);

      const params = {
        tour: tour,
        reorderPois: reordering
      };
      console.log('going to recalculate tour:', params);

      const response = await dataApi.post('recalculation', params);
      if (response.status === 200) {
        console.log('inside Action recalculateTour:', response);
        let action = updateTour(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function getTourTemplates() {
  return async dispatch => {
    dispatch(toursLoading());
    try {
      const params = { locale: LOCALE };
      const response = await dataApi.fetch('tourTemplates', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = setData(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function saveTourTemplate(payload) {
  console.log('Action: going to save tour template: ', payload);

  return async dispatch => {
    let setLoaderAction = setLoader();
    dispatch(setLoaderAction);

    try {
      const response = await dataApi.post('tourTemplate', payload);

      if (response.status === 200) {
        console.log('inside Action:', response);

        let action = saveTemplate(response.data);
        if (!payload.id) {
          //if there is no id, this means that we are creating a new template
          console.log('creating new template...');
          action = createNewTemplate(response.data);
        }
        console.log('action to dispatch', action);

        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function getTourTemplate(id) {
  return async dispatch => {
    let setLoaderAction = setLoader();
    dispatch(setLoaderAction);

    try {
      const params = { id: id, locale: LOCALE };
      const response = await dataApi.fetch('tourTemplate', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = selectTemplate(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function stabilizeTour(id) {
  return async dispatch => {
    let setLoaderAction = setLoader();
    dispatch(setLoaderAction);

    try {
      const params = { id: id };
      const response = await dataApi.update('stabilizeTour', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = selectTemplate(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}
