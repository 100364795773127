import axios from 'axios';

/** Backend URL */
const my_backend = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : 'http://localhost:8080/';
/**
 * AXIOS CONFIG
 */
let axiosInstance = axios.create({
  baseURL: my_backend
});

/**
 * INTERCEPTORS
 */
axiosInstance.interceptors.request.use(
  async config => {
    // add tokens and headers
    console.log('\x1b[35m%s\x1b[0m', '[AUTH REQUEST] \n', config.url);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    console.log('\x1b[35m%s\x1b[0m', '[AUTH RESPONSE] \n', response);
    return response;
  },
  error => {
    return error.response;
  }
);

var config = {
  headers: {
    'Content-type': 'application/json'
  }
};

/**
 * Login user to API
 * @param {*} user
 */
const login = user => {
  let url = '/authentication/login/';
  return axiosInstance
    .post(url, user, config)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

/**
 * Register user to API
 * @param {*} user
 */
const register = user => {
  let url = '/authentication/sign-up';
  return axiosInstance
    .post(url, user, config)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
};

export default {
  login,
  register
};
