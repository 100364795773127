import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Suggested Tours"
            icon="map"
            route="/tourTemplates"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Points of Interest (POIs)"
            icon="map-marker"
            route="/pois"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Statistics"
            icon="chart-bars"
            route="/statistics"
            onClick={this.hideSidebar}
          />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
