import React from 'react';

// CUSTOM SCSS
import './Policy.scss';

const Policy = () => (
  <div className="page__wrapper">
    <p>
      <strong>Just 60 minutes Privacy Policy</strong>
    </p>
    <p>
      This privacy policy forms part of the Terms of Use of{' '}
      <strong>Just 60 minutes</strong> (also referred to as &ldquo;we&rdquo;,
      &ldquo;us&rdquo;, &ldquo;our&rdquo; or &ldquo;Service&rdquo;). The Terms
      of Use are available <a href="./terms">here</a>.
    </p>
    <p>
      The purpose of this Privacy Policy is to inform you of the details of how
      Just 60 minutes processes your personal data.
    </p>
    <p>
      <strong>ABOUT Just 60 minutes</strong>
    </p>
    <p>
      Just 60 minutes (also referred to as &ldquo;<strong>Just60&rsquo;</strong>
      &rdquo;, &ldquo;<strong>we</strong>&rdquo;, or &ldquo;
      <strong>Service</strong>&rdquo;) is a mobile application for the promotion
      of tourist and cultural values in the Region of Central Macedonia, through
      personalized tours.
    </p>
    <p>
      <strong>DEFINITIONS</strong>
    </p>
    <p>
      <strong>"Services" </strong>means any product or other service that is
      operated by Just 60 minutes.
    </p>
    <p>
      <strong>
        "Users", &ldquo;you&rdquo;, &ldquo;your&rdquo; or similar terms{' '}
      </strong>
      means any individual who has an account with Just 60 minutes.
    </p>
    <p>
      <strong>"GDPR" </strong>means the General Data Protection Regulation.
    </p>
    <p>
      Terms not defined in this Privacy Policy or Just 60 minutes Terms of Use
      will have the meaning given in the GDPR and the Greek Data Protection Law
      (Law 4624/2019).
    </p>
    <p>
      <strong>ABOUT THE ORGANIZATION RESPONSIBLE FOR </strong>
      <strong>Just 60 minutes</strong>
    </p>
    <p>
      Responsible for the operation of Just 60&rsquo; and counterparty to these
      Terms of Use is the REGION OF CENTRAL MACEDONIA (&ldquo;RCM&rdquo;).
    </p>
    <p>
      Address: 26<sup>th</sup> of October str. 64, Thessaloniki, Greece
    </p>
    <p>PC: GR54627</p>
    <p>Tel: +30 2313 330052, +30 2313 330918, +30 2313 325449</p>
    <p>
      Email: <a href="mailto:info@pkm.gov.gr">info@pkm.gov.gr</a>
    </p>
    <p>
      <strong>ABOUT THE APPLICABLE LAW</strong>
    </p>
    <p>
      The handling and protection of the personal data processed by Just 60
      minutes is governed by the terms hereof and the provisions of GDPR and
      Greek law on the protection of individuals with regard to the processing
      of personal data (Law 4624/2019).
    </p>
    <p>
      <strong>ABOUT THE DATA COLLECTED ABOUT YOU</strong>
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong> Information you provide to us</strong>
      </li>
    </ol>
    <p>
      <strong>Information you provide through our Mobile Application</strong>
    </p>
    <p>
      You provide your username, password and email to register and access the
      Just 60 minutes mobile application.
    </p>
    <p>
      <strong>
        Information you provide through our Mobile Application Services
      </strong>
    </p>
    <p>
      When you use our Mobile Application you provide data such as your
      location, your preferences related to POIs, time, transportation and
      personal reviews and comments of POIs.
    </p>
    <p>
      <strong>
        Information you provide through our support channel or direct
        communication
      </strong>
    </p>
    <p>
      Through our support channel (Feedback sent though the Just 60 minutes
      application, emails sent at tourismos@pkm.gov.gr) or direct email
      communication you provide us with data about yourself and about the case
      for which you are contacting us.
    </p>
    <ol>
      <li>
        <strong> Information we collect automatically</strong>
      </li>
    </ol>
    <p>
      <strong>Information on how you use our Application</strong>
    </p>
    <p>
      When you visit and interact with the Just 60 minutes application, we
      collect certain information in the background in order to optimize and
      improve the Just 60 minutes Services. This includes log data, logins and
      time stamps.
    </p>
    <p>
      <strong>ABOUT THE PURPOSES FOR WHICH YOUR DATA WILL BE PROCESSED</strong>
    </p>
    <p>
      Just 60 minutes processes your personal data to deliver you services
      offered through the Just 60 minutes Application. Specifically, we may use
      information we receive to:
    </p>
    <p>Create your account;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>WHEN WE MAY SHARE YOUR PERSONAL DATA</strong>
    </p>
    <p>
      Except as provided in this Privacy Policy, we will never use the provided
      personal data for any other purpose than to enable the use of the
      Application by you.
    </p>
    <p>We may only share information we have collected about you:</p>
    <p>
      (1) where we are legally required to do so, such as in response to court
      orders or legal process, or to establish, protect, or exercise our legal
      rights;
    </p>
    <p>
      (2) if we are acquired by or merged with another entity (in which case we
      will require such entity to assume our obligations under this Privacy
      Policy or inform you that you are covered by a new privacy policy).
    </p>
    <p>
      (3) we may also disclose information to our sub-contractors and service
      providers in order to facilitate the operability and functioning of the
      Application. We work with third-party service providers for hosting the
      Application services. If we transfer your personal data to a service
      provider in order to fulfill a task, we will make sure that the service
      provider only processes the data based on our instructions and by
      guaranteeing the same safeguards as we do. To this effect, we have bound
      our data processors with data processing agreements concluded pursuant to
      Article 28 of the GDPR and, where such processor reside outside of the
      EEA, we have concluded Standard Contractual Clauses (model clauses)
      approved by the European Commission (2010/87/EU) with such processors, or
      (with respect to US-based companies) verified that these processors have
      an active Privacy Shield certification. In particular, we use the
      following third party contractors:
    </p>
    <ul>
      <li>SMTP mail service: Athens Technology Center</li>
      <li>Hosting backend: Heroku by Salesforce</li>
      <li>Hosting images: Amazon S3 - Amazon Web Services S3</li>
      <li>Database: Mongo Atlas</li>
    </ul>
    <p>
      <strong>SECURITY</strong>
    </p>
    <p>
      Appropriate and detailed security policies, rules, and technical measures
      are implemented to protect your personal data that are used by the
      Application and are stored from improper or unauthorised access.
    </p>
    <p>&nbsp;</p>
    <p>
      All employees and data processors, who have access to and are associated
      with the processing of personal data, are obliged to respect the
      confidentiality of your personal data. Our Data Protection Policy ensures
      that all processes we follow are fully compliant with the GDPR provisions.
    </p>
    <p>
      We are also reviewing our data protection processes regularly in order to
      make sure that we protect your data in the best possible way. If we use
      sub-contractors and service providers to fulfil a specific task based on
      your data, we will make sure that the partner has similar state-of-the-art
      data protection processes in place.
    </p>
    <p>
      <strong>ABOUT YOUR RIGHTS</strong>
    </p>
    <p>
      Just 60 minutes respects all your rights and takes all steps necessary to
      ensure their protection. Under the General Data Protection Regulation
      [Articles 15-21], you have a number of important rights. In summary, those
      include the following rights:
    </p>
    <p>
      <strong>Right of access:</strong> You have the right to be aware and
      verify the legitimate nature of the processing. So, you have the right to
      access your personal data and receive additional information about how we
      process it.
    </p>
    <p>
      <strong>Right to rectification:</strong> You have the right to study,
      correct, update or modify your personal data by contacting Just 60
      minutes.
    </p>
    <p>
      <strong>Right to erasure:</strong> You have the right to request deletion
      of your personal data when we process it with your consent or in order to
      protect our legitimate interests. In all other cases (such as, where there
      is a contract, obligation to process personal data legally required, or
      public interest), this right is subject to specific restrictions or shall
      not exist, as the case may be.
    </p>
    <p>
      <strong>Right to restriction of processing:</strong> You have the right to
      request a restriction of the processing of your personal data in the
      following cases: (a) when the accuracy of the personal data is contested
      and until the accuracy is verified, (b) when you oppose the deletion of
      your personal data and request the restriction of their use instead, c)
      when personal data are not needed for processing purposes, they are
      however required for the establishment, exercise, or defense of legal
      claims, and (d) when you object to the processing and the decision on your
      objection to processing is pending.
    </p>
    <p>
      <strong>Right to object to processing:</strong> You have the right to
      object at any time to the processing of your personal data where, as
      described above, the processing is based on the legitimate interests we
      pursue as data controllers, as well as, for the purposes of direct
      marketing and consumer profiling, if applicable.
    </p>
    <p>
      <strong>Right to data portability:</strong> You have the right to receive
      your personal data free of charge in a format that allows you to access,
      use, and edit them with commonly used editing methods. You also have the
      right to ask us, in case it is technically feasible, to transmit the data
      directly to another controller. Your right to do so exists for the data
      you have provided to us and is processed by automated means based on your
      consent or for the execution of a relevant contract.
    </p>
    <p>
      <strong>Right to withdraw your consent:</strong> In cases where processing
      is based on your consent, you have the right to withdraw it without
      affecting the lawfulness of processing based on consent prior to its
      withdrawal.
    </p>
    <p>If you would like to exercise any of those rights, please:</p>
    <ul>
      <li>contact us using our Contact details below,</li>
      <li>provide us with enough information to identify you,</li>
      <li>provide us with proof of your identity and address, and</li>
      <li>let us know the information to which your request relates.</li>
    </ul>
    <p>
      Address: 26<sup>th</sup> of October str. 64, Thessaloniki, Greece
    </p>
    <p>PC: GR54627</p>
    <p>Tel: +30 2313 330052, +30 2313 330918, +30 2313 325449</p>
    <p>
      Email: <a href="mailto:info@pkm.gov.gr">info@pkm.gov.gr</a>
    </p>
    <p>
      <strong>RETENTION PERIODS</strong>
    </p>
    <p>
      We retain your personal data for the period necessary to fulfil the
      purposes outlined in this Privacy Policy and/or any Services agreement,
      unless a longer retention is required by law (e.g. for tax or accounting
      purposes or due to other legal requirements) or storing of the data is
      needed for the establishment, exercise or defence of REGION OF CENTRAL
      MACEDONIA legal claims; in such case, we will store only the data
      necessary for the enforcement of our claims or our defence for the period
      necessary in the given case and not exceeding the statutory limitation
      periods.
    </p>
    <p>
      <strong>CHANGES TO THIS POLICY</strong>
    </p>
    <p>
      We reserve the right to change this Privacy Policy. We will provide
      notification of material changes through the Just 60 minutes Service prior
      to the change taking effect. Your continued use of the Application after
      the update has become effective indicates that you have read, understood,
      and agreed to the new version of this Policy.
    </p>
    <p>
      <strong>CONTACT INFORMATION</strong>
    </p>
    <p>
      For more information or if you have any query regarding our Privacy
      Policy, please contact us at{' '}
      <a href="mailto:info@pkm.gov.gr">info@pkm.gov.gr</a>.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>"
  </div>
);

export default Policy;
