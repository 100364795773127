import React from 'react';
import PropTypes from 'prop-types';

// ICONS
import CloseIcon from 'mdi-react/CloseIcon';

// CUSTOM SCSS
import './Error.scss';

const Error = ({ error, page, onErrorClose }) => {
  return (
    <>
      {error && (
        <div className={`error-container ${page ? ' page-error' : ''}`}>
          {error}
          {onErrorClose && (
            <button className="close_btn" type="button" onClick={onErrorClose}>
              <CloseIcon />
            </button>
          )}
        </div>
      )}
    </>
  );
};

Error.propTypes = {
  error: PropTypes.string
};

Error.defaultProps = {
  error: ''
};

export default Error;
