import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';

// CUSTOM COMPONENTS
import InputField from '../InputField/InputField';
import SelectField from '../SelectField/SelectField';

// CUSTOM DATA
import { TRANSPORTATION } from '../../shared/utils/utils';

// CUSTOM SCSS
import './NewTour.scss';

class NewTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title_en: '',
      title_el: '',
      transportationType: 'DRIVING'
    };
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const newTour = {
      title: this.state.title_en,
      waypointList: [],
      inactive: true,
      transportationType: this.state.transportationType,
      polylineDecoded: [],
      titleTranslations: {
        en: this.state.title_en,
        el: this.state.title_el
      }
    };
    this.props.handleSubmit(newTour);
  };

  render() {
    return (
      <form
        className="form new_tour_form"
        onSubmit={event => this.handleSubmit(event)}
      >
        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              type="text"
              name="title_en"
              placeholder="Enter a title"
              label="Title (EN)"
              texture="material"
              value={this.state.title_en || ''}
              required
              onChange={event => this.handleChange(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              type="text"
              name="title_el"
              placeholder="Enter a title"
              label="Title (GR)"
              texture="material"
              value={this.state.title_el || ''}
              required
              onChange={event => this.handleChange(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <SelectField
              options={TRANSPORTATION}
              value={this.state.transportationType}
              name="transportationType"
              id={'transportation'}
              placeholder="Transportation"
              onChange={event => this.handleChange(event)}
              editable={true}
            />
          </div>
        </div>

        <ButtonToolbar className="form__button-toolbar d-flex justify-content-end">
          <Button type="button" onClick={() => this.props.toggle()}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Add
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}
export default NewTour;
