import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import { login, resetRedux } from '../../redux/actions/authActions';

// CUSTOM COMPONENTS
import InputField from '../InputField/InputField';
import Loader from '../Loader/Loader';

import './LoginForm.scss';

class LogInForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      user: { username: '', password: '' },
    };
  }

  /**
   * Reset redux to initial state if error or loading is not null
   */
  componentWillUnmount() {
    if (this.props.error || this.props.loading) {
      this.props.resetRedux();
    }
  }

  /**
   * Displays password
   * @param {*} e
   */
  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    console.log('handleChange', name, value);
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  }

  /**
   * Call redux action to login
   * @param {*} e
   */
  login(e) {
    e.preventDefault();
    if (this.state.user.username && this.state.user.password) {
      this.props.login(this.state.user);
    }
  }

  render() {
    return (
      <form className="form login-form" onSubmit={(event) => this.login(event)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <InputField
              onChange={(event) => this.handleChange(event)}
              type="text"
              name="username"
              placeholder="Your name"
              showError={false}
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <InputField
              onChange={(event) => this.handleChange(event)}
              type={this.state.showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Your password"
              showError={false}
              required
            />
            <button
              type="button"
              className={`form__form-group-button${
                this.state.showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
            >
              <EyeIcon />
            </button>
            {/* TODO Implement forgot password */}
            {/* <div className="account__forgot-password">
              <a href="/register">Forgot password?</a>
            </div> */}
          </div>
        </div>
        <div className="account__btns">
          <Button className="account__btn" color="success" type="submit">
            Sign In
          </Button>
          {/* <Link className="btn btn-outline-success account__btn" to="/register">
            Create Account
          </Link> */}
          {this.props.token && <Redirect push to="/" />}
        </div>
        <Loader loading={this.props.loading} simple={true}></Loader>
        {this.props.error && !this.props.loading && (
          <div className="error_message">{this.props.error} </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    resetRedux: () => dispatch(resetRedux()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
