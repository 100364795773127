import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

// CUSTOM COMPONENTS
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import PoiMap from '../PoiMap/PoiMap';
import InputField from '../InputField/InputField';
import SelectField from '../SelectField/SelectField';
import TextAreaField from '../TextAreaField/TextAreaField';
import Geocode from 'react-geocode';

// CUSTOM DATA
import { CATEGORIES } from '../../shared/utils/utils';

// CUSTOM SCSS
import './NewPOI.scss';

class NewPOI extends Component {
  constructor(props) {
    super(props);
    console.log('NewPOI: constructor called');
    this.state = {
      address: '',
      position: { lat: 37.9743470423469, lng: 23.7081520298828 },
      coords: '37.9743470423469, 23.7081520298828',
      changeMapCenter: true,
      showCoords: false
    };
    this.onMapClick = this.onMapClick.bind(this);
  }

  componentDidMount() {
    // Loads initial data
    console.log('NewPOI: inside componentDidMount()');
    let address = '';
    if (this.props.poi.latitude) {
      address = this.props.poi.address;

      let position = {
        lat: this.props.poi.latitude,
        lng: this.props.poi.longitude
      };

      let coords = this.props.poi.latitude + ', ' + this.props.poi.longitude;

      this.setState({
        coords: coords,
        position: position,
        changeMapCenter: true
      });
    }
    this.setState({
      address: address
    });
  }

  componentDidUpdate(prevProps, previousState) {
    console.log('NewPOI: inside componentDidUpdate()');

    console.log('prevProps.poi.address: ', prevProps.poi.address);
    console.log('this.props.poi.address', this.props.poi.address);
    console.log('this.state.address', this.state.address);
    console.log('poi props', this.props);

    if (prevProps.poi.address !== this.props.poi.address) {
      console.log('poi props', this.props.poi);
      let address = this.props.poi.address;
      console.log('address: ', address);

      let position = {
        lat: this.props.poi.latitude,
        lng: this.props.poi.longitude
      };
      console.log('position inside constructor:', position);

      let coords = this.props.poi.latitude + ', ' + this.props.poi.longitude;

      this.setState({
        address: address,
        coords: coords,
        position: position,
        changeMapCenter: true
      });
    }
  }

  calculateDuration = (duration) => {
    return Math.floor(duration / 60);
  };

  handleChange = (address) => {
    console.log('handleChange called...', address);
    this.setState({ address });
  };

  handleCoordsChange = (event) => {
    const coords = event.target.value.split(',');
    const lat = parseFloat(coords[0]);
    const lng = parseFloat(coords[1]);
    const position = { lat: lat, lng: lng };
    this.setState({
      coords: event.target.value,
      position,
      changeMapCenter: true
    });

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);

        this.setState({
          address: address,
          changeMapCenter: false
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  handleSelect = (address) => {
    console.log('handleSelect called...');
    console.log('select Address: ', address);

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log('Success', latLng);

        const position = latLng;

        this.setState({
          address,
          position,
          coords: position.lat + ', ' + position.lng,
          changeMapCenter: true
        });
      })
      .catch((error) => console.error('Error', error));
  };

  saveChanges = (e) => {
    e.preventDefault();
    let newPoi = Object.assign(this.props.poi);
    newPoi.address = this.state.address;
    newPoi.latitude = this.state.position.lat;
    newPoi.longitude = this.state.position.lng;

    newPoi.location = {
      type: 'Point',
      coordinates: [this.state.position.lat, this.state.position.lng]
    };
    this.props.savePoi(newPoi);
  };

  onMapClick(e) {
    if (!this.props.editable) {
      return;
    }
    console.log('onMapClick called', e.latLng);
    let coords = e.latLng;

    Geocode.setLanguage('en');
    Geocode.fromLatLng(coords.lat(), coords.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);

        this.setState({
          coords: coords.lat() + ', ' + coords.lng(),
          position: { lat: coords.lat(), lng: coords.lng() },
          address: address,
          changeMapCenter: false
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  toggleCoords = () => {
    this.setState({
      showCoords: !this.state.showCoords
    });
  };

  render() {
    return (
      <form
        className="form new_poi_form"
        onSubmit={(event) => this.saveChanges(event)}
      >
        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              readOnly={!this.props.editable}
              type="text"
              name="title_en"
              placeholder="Enter a title"
              label="Title (EN)"
              texture="material"
              value={this.props.poi.nameTranslations.en || ''}
              required
              onChange={(event) => this.props.handleChange(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              readOnly={!this.props.editable}
              type="text"
              name="title_el"
              placeholder="Enter a title"
              label="Title (GR)"
              texture="material"
              value={this.props.poi.nameTranslations.el || ''}
              required
              onChange={(event) => this.props.handleChange(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            {this.props.editable ? (
              <InputField
                readOnly={!this.props.editable}
                type="text"
                name="photoURL"
                placeholder="Enter a photo url"
                label="Photo"
                texture="material"
                value={this.props.poi.photoURL || ''}
                onChange={(event) => this.props.handleChange(event)}
              />
            ) : (
              <div className="poi_image_container">
                <div className="poi_image_label">Photo *</div>
                <img
                  src={this.props.poi.photoURL}
                  alt="Poi"
                  className="poi_image"
                />
              </div>
            )}
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <SelectField
              options={CATEGORIES}
              value={this.props.poi.type || 'culture'}
              name="type"
              id="categories"
              placeholder="Select a category"
              onChange={(event) => this.props.handleChange(event)}
              editable={this.props.editable}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              readOnly={!this.props.editable}
              type="text"
              name="proposedDuration"
              placeholder="Enter a proposed duration in minutes"
              label="Duration (mins)"
              texture="material"
              value={
                this.calculateDuration(this.props.poi.proposedDuration) || ''
              }
              required
              onChange={(event) => this.props.handleChange(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            {this.props.editable ? (
              <div className="address_container d-flex flex-column align-items-end">
                <PlacesAutocomplete
                  className="flex-fill"
                  value={this.state.address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  placeholder="Search a specific address"
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                  }) => (
                    <>
                      <TextField
                        className="material-form__field"
                        variant="outlined"
                        label="Address"
                        readOnly={!this.props.editable}
                        required
                        {...getInputProps({
                          placeholder: 'Search a specific address'
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          return (
                            <div
                              className={`dropdown_row ${className}`}
                              {...getSuggestionItemProps(suggestion)}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>

                <div className="coords_checkbox d-flex justify-content-center align-items-center">
                  <Checkbox onChange={() => this.toggleCoords()} />I want to
                  fill in coordinates
                </div>
              </div>
            ) : (
              <InputField
                readOnly={true}
                type="text"
                name="address"
                label="Address"
                texture="material"
                value={this.props.poi.address || ''}
                required
              />
            )}
          </div>

          {this.props.editable && this.state.showCoords && (
            <div className="form__form-group coords_form_group">
              <div className="form__form-group-field">
                <InputField
                  readOnly={!this.props.editable}
                  type="text"
                  name="coords"
                  placeholder="Enter a coordinates separated by comma"
                  label="Coordinates"
                  texture="material"
                  value={this.state.coords || ''}
                  required
                  onChange={(event) => this.handleCoordsChange(event)}
                />
              </div>
            </div>
          )}

          <div className="map_area">
            <PoiMap
              position={this.state.position}
              onMapClick={this.onMapClick}
              changeMapCenter={this.state.changeMapCenter}
            ></PoiMap>
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <TextAreaField
              type="text"
              name="description_en"
              placeholder="Enter a description"
              label="Description (EN)"
              texture="material"
              value={this.props.poi.descriptionTranslations.en || ''}
              onChange={(event) => this.props.handleChange(event)}
              // required
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <TextAreaField
              type="text"
              name="description_el"
              placeholder="Enter a description"
              label="Description (GR)"
              texture="material"
              value={this.props.poi.descriptionTranslations.el || ''}
              onChange={(event) => this.props.handleChange(event)}
              // required
            />
          </div>
        </div>

        {this.props.editable && (
          <ButtonToolbar className="form__button-toolbar d-flex justify-content-end">
            <Button type="button" onClick={() => this.props.toggle()}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              {this.props.poi.name ? 'Save' : 'Add'}
            </Button>
          </ButtonToolbar>
        )}
      </form>
    );
  }
}

export default NewPOI;
