import React, { useState } from 'react';
import { checkPerName } from '../../services/validationFields/validationFields';
import ErrorInput from '../ErrorInput/ErrorInput';
import TextField from '@material-ui/core/TextField';

const TextAreaField = props => {
  /** TextAreaField state */
  const [errorMessage, setError] = useState();
  /** Setup props for input */
  const inputProps = {
    name: props.name,
    type: props.type,
    label: props.label,
    placeholder: props.placeholder,
    required: props.required ? props.required : false
  };

  /**
   * Validates input, displays error message and sends check to parent component
   * @param {*} value
   */
  const checkValue = value => {
    var errorMessage = checkPerName(props.name, value);
    if (errorMessage) {
      setError(errorMessage);
    } else if (props.valueCheck) {
      props.valueCheck(value, props.name);
    }
  };

  /**
   * Removes error display upon change value
   */
  const changeValue = event => {
    if (errorMessage) {
      setError();
    } else if (props.onChange) {
      props.onChange(event);
    }
  };

  /**
   * Theme uses different types of input fields depending on route
   * so are we by texture props
   * @param {String} texture
   */
  const inputType = texture => {
    if (texture === 'material') {
      return (
        <TextField
          className="material-form__field"
          label={props.label}
          placeholder={props.placeholder}
          error={errorMessage}
          onChange={e => changeValue(e)}
          onBlur={e => checkValue(e.target.value)}
          variant="outlined"
          multiline
          rows="4"
          value={props.value || ''}
          {...inputProps}
        />
      );
    } else {
      return (
        <>
          <label>{props.label}</label>
          <input
            {...inputProps}
            onBlur={e => checkValue(e.target.value)}
            onChange={e => changeValue(e)}
          />
          <ErrorInput errorMessage={errorMessage} />
        </>
      );
    }
  };

  return <>{inputType(props.texture)}</>;
};

export default TextAreaField;
