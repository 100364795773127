import React, { useState } from 'react';

// CUSTOM COMPONENTS
import InputField from '../InputField/InputField';

// ICONS
import CloseIcon from 'mdi-react/CloseIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';

// CUSTOM SCSS
import './FilterInput.scss';

const FilterInput = props => {
  const [term, setTerm] = useState('');

  const updateSearchTerm = event => {
    setTerm(event.target.value);
  };

  const clearSearchTerm = event => {
    setTerm('');
    props.onClear('');
  };

  return (
    <div className="d-flex filter_input">
      <InputField
        readOnly={false}
        type="text"
        name="filter"
        placeholder="Title"
        label="Filter pois by title"
        texture="material"
        value={term}
        onChange={event => updateSearchTerm(event)}
        onEnter={() => props.onSubmit(term)}
      />
      <div className="buttons d-flex align-items-center">
        {term && (
          <CloseIcon className="close_icon" onClick={() => clearSearchTerm()} />
        )}
        <ArrowRightIcon
          className="submit_icon"
          onClick={() => props.onSubmit(term)}
        />
      </div>
    </div>
  );
};

export default FilterInput;
