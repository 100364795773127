import dataApi from '../../../services/data/dataApi';

// CUSTOM DATA
import { LOCALE } from '../../../shared/utils/utils';

export const FETCH_STATISTICS_LOADING = 'FETCH_STATISTICS_LOADING';
export const FETCH_STATISTICS_SUCCESSFUL = 'FETCH_STATISTICS_SUCCESSFUL';
export const FETCH_STATISTICS_ERROR = 'FETCH_STATISTICS_ERROR';

function loading(payload) {
  return {
    type: FETCH_STATISTICS_LOADING,
    payload
  };
}

function setData(payload) {
  return {
    type: FETCH_STATISTICS_SUCCESSFUL,
    payload
  };
}

function setError(payload) {
  return {
    type: FETCH_STATISTICS_ERROR,
    payload
  };
}

export function getStatistics(duration) {
  return async dispatch => {
    dispatch(loading(duration));
    try {
      const params = { duration, locale: LOCALE };
      const response = await dataApi.fetch('statistics', params);
      if (response.status === 200) {
        dispatch(setData(response.data));
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function updateDuration(duration) {
  return async dispatch => {
    dispatch(loading(duration));
    try {
      const params = { duration, locale: LOCALE };
      const response = await dataApi.fetch('statistics', params);
      if (response.status === 200) {
        dispatch(setData(response.data));
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}
