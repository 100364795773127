import {
  TOUR_LOADING,
  FETCH_TOUR_TEMPLATES_SUCCESSFUL,
  FETCH_TOUR_TEMPLATE_SUCCESSFUL,
  ADD_POI_TO_TEMPLATE,
  REMOVE_POI_FROM_TEMPLATE,
  RECALCULATE_TOUR_SUCCESSFUL,
  FETCH_TOUR_TEMPLATE,
  TOUR_TEMPLATES_ERROR,
  REORDER_TOUR_POIS_SUCCESSFUL,
  SAVE_TOUR_TEMPLATE_SUCCESSFUL,
  CHANGE_TRANSPORTATION_TYPE,
  CHANGE_FLAG_INACTIVE,
  NEW_TEMPLATE_CREATED
} from '../../actions/tourTemplates/tourTemplatesActions';

const initialState = {
  error: '',
  modal: false,
  fetching: false,
  data: [],
  selectedTemplate: { waypointList: [], polylineDecoded: [] },
  showRecalculateButtons: false,
  saved: false
};

export default function(state = initialState, action) {
  console.log('tourTemplateReducer called');
  switch (action.type) {
    case TOUR_LOADING: {
      return {
        ...state,
        error: '',
        fetching: true,
        saved: false
      };
    }
    case TOUR_TEMPLATES_ERROR: {
      return {
        ...state,
        error: action.payload,
        fetching: false
      };
    }
    case REMOVE_POI_FROM_TEMPLATE: {
      let template = state.selectedTemplate;
      let id = action.payload.id;

      console.log('remove: ', template);
      console.log('remove id', id);

      template.waypointList = template.waypointList.filter(
        poi => id !== poi.id
      );
      console.log('remove waypoint list', template.waypointList);

      return {
        ...state,
        error: '',
        selectedTemplate: template,
        showRecalculateButtons: true,
        showSaveButton: false
      };
    }
    case CHANGE_TRANSPORTATION_TYPE: {
      let template = state.selectedTemplate;
      let transportationType = action.payload;

      console.log('going to set transportationType', transportationType);

      template.transportationType = transportationType;

      return {
        ...state,
        error: '',
        selectedTemplate: template,
        showRecalculateButtons: true,
        showSaveButton: false
      };
    }

    case NEW_TEMPLATE_CREATED: {
      const newTemplate = action.payload;

      console.log('received NEW_TEMPLATE_CREATED', newTemplate);

      let newData = state.data;
      newData.push(newTemplate);

      return {
        ...state,
        data: newData,
        error: '',
        fetching: false
      };
    }

    case CHANGE_FLAG_INACTIVE: {
      let template = state.selectedTemplate;
      let inactive = action.payload;

      console.log('going to set inactive flag', inactive);

      template.inactive = inactive;

      return {
        ...state,
        error: '',
        selectedTemplate: template,
        showRecalculateButtons: false,
        showSaveButton: true
      };
    }

    case FETCH_TOUR_TEMPLATE_SUCCESSFUL: {
      console.log('inside FETCH_TOUR_TEMPLATE_SUCCESSFUL');
      return {
        ...state,
        selectedTemplate: action.payload,
        fetching: false,
        showRecalculateButtons: false,
        showSaveButton: false,
        newTemplateId: ''
      };
    }
    case SAVE_TOUR_TEMPLATE_SUCCESSFUL: {
      console.log('inside SAVE_TOUR_TEMPLATE_SUCCESSFUL');
      return {
        ...state,
        fetching: false,
        showRecalculateButtons: false,
        showSaveButton: false
      };
    }

    case REORDER_TOUR_POIS_SUCCESSFUL: {
      console.log('inside REORDER_TOUR_POIS_SUCCESSFUL');
      return {
        ...state,
        fetching: false,
        showRecalculateButtons: true,
        showSaveButton: false
      };
    }
    case ADD_POI_TO_TEMPLATE: {
      let template = state.selectedTemplate;
      let poi = action.payload;

      let exists = template.waypointList.find(item => {
        return item.id === poi.id;
      });

      if (exists) {
        return {
          ...state,
          error: 'This is wrong',
          template: template
        };
      }

      template.waypointList.push(poi);

      console.log('template after adding poi', template);

      return {
        ...state,
        selectedTemplate: template,
        showRecalculateButtons: true,
        showSaveButton: false
      };
    }
    case FETCH_TOUR_TEMPLATE: {
      console.log('REDUX: FETCH_TOUR_TEMPLATE', action.payload);
      return {
        ...state,
        error: '',
        fetching: true,
        showSaveButton: false
      };
    }
    case FETCH_TOUR_TEMPLATES_SUCCESSFUL: {
      console.log('inside FETCH_TOUR_TEMPLATES_SUCCESSFUL', action.payload);
      return {
        ...state,
        data: action.payload,
        error: '',
        fetching: false
      };
    }
    case RECALCULATE_TOUR_SUCCESSFUL: {
      console.log('inside RECALCULATE_TOUR_SUCCESSFUL', action.payload);
      return {
        ...state,
        selectedTemplate: action.payload,
        error: '',
        fetching: false,
        showRecalculateButtons: false,
        showSaveButton: true
      };
    }
    default:
      return state;
  }
}
