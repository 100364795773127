import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

import authStorage from '../../../services/authentication/authStorage';

import { connect } from 'react-redux';
import { setUser, logout } from '../../../redux/actions/authActions';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    user: UserProps.isRequired
  };

  componentDidMount() {
    if (!this.props.user.username && authStorage.getAuth('auth')) {
      this.props.setUser();
    }
  }

  logout = () => {
    this.props.logout(null);
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      user
    } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {user.username && (
              <TopbarSidebarButton
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                changeSidebarVisibility={changeSidebarVisibility}
              />
            )}
            <Link className="topbar__logo" to="/" />
          </div>
          <div className="topbar__right">
            {user.username && (
              <TopbarProfile user={user} logout={this.logout} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    setUser: () => dispatch(setUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
