import React from 'react';
import LogInForm from '../../components/LogInForm/LogInForm';

import LogoIcon from '../../shared/img/logo/logo-icon.png';
import LogoText from '../../shared/img/logo/logo-text.png';

const LogIn = () => (
  <div className="account account--photo">
    <div className="account__wrapper">
      <div className="account_logo_container">
        <img src={LogoIcon} alt="Just 60' Icon" />
      </div>
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Welcome to</h3>
          <h4 className="account__subhead subhead">
            <img src={LogoText} alt="Just 60' Dashboard" />
          </h4>
        </div>
        <LogInForm form="log_in_form" />
      </div>
    </div>
  </div>
);

export default LogIn;
