import React, { PureComponent } from 'react';
import { Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

// REDUX
import { connect } from 'react-redux';
import {
  getTourTemplate,
  reorderPois,
  removePoiFromTemplate,
  recalculateTour,
  saveTourTemplate,
  addPoiToTemplate,
  changeTransportationType,
  changeFlagInactive,
  setError,
  stabilizeTour,
} from '../../redux/actions/tourTemplates/tourTemplatesActions';
import {
  fetchAdditionalPois,
  togglePoiInfoWindow,
  clearPois,
  getPois,
} from '../../redux/actions/pois/poisActions';

// CUSTOM COMPONENTS
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import Message from '../../components/Message/Message';
import SelectField from '../../components/SelectField/SelectField';
import Poi from '../../components/Poi/Poi';
import Customizer from '../../components/Customizer/Customizer';
import PoisList from '../../components/PoisList/PoisList';

// CUSTOM DATA
import { TRANSPORTATION, STATUS } from '../../shared/utils/utils';

// ICONS
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

// CUSTOM SCSS
import './TourTemplate.scss';

class TourTemplate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      template: {},
      showPoisPanel: false,
      showTemplateDetails: false,
      modal: false,
      selectedPoi: {},
      open: false,
      test: true,
      changeCenter: true,
      selectedCategory: '',
    };

    this.onClickPoi = this.onClickPoi.bind(this);
    this.addToTemplate = this.addToTemplate.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    console.log('received id:', id);
    this.props.getTourTemplate(id);
  }

  componentWillUnmount() {
    this.props.clearPois();
  }

  onClickPoi(item, e) {
    this.props.togglePoiInfoWindow(item.id);
    // item.isOpen = item.isOpen ? false : true;
    // this.setState(prevState => ({
    //   test: !prevState.test,
    //   changeCenter: false
    // }));
  }

  fetchTourTemplateFromDB() {
    const { id } = this.props.match.params;
    console.log('received id:', id);
    this.props.getTourTemplate(id);
  }

  saveTourTemplate(tour) {
    console.log('saveTourTemplate called', tour);
    this.props.saveTourTemplate(tour);
  }

  handleOpen = () => {
    const tour = this.props.tourTemplates.selectedTemplate;
    const excluded = tour.waypointList.map((poi) => poi.id);
    console.log('going to exclude', excluded);
    this.props.fetchAdditionalPois({ excluded: excluded });
    this.setState((prevState) => ({
      open: !prevState.open,
      changeCenter: true,
    }));
  };

  /**
   * Handles changes in category filter from poi's form
   *
   * @param {*} event
   */
  handleCategoryFilter = (event) => {
    const value = event.target.value;

    console.log('filter by category: ' + value);

    this.props.clearPois();
    const tour = this.props.tourTemplates.selectedTemplate;
    const excluded = tour.waypointList.map((poi) => poi.id);

    this.setState(
      {
        searchTerm: value,
        pois: [],
        disabledScroll: value !== '',
        previousScrollTop: 0,
        page: 0,
        selectedCategory: value,
      },
      () => {
        if (value) {
          this.props.fetchAdditionalPois({
            excluded: excluded,
            categories: value,
          });
        } else {
          this.props.fetchAdditionalPois({ excluded: excluded });
        }
      }
    );
  };

  addToTemplate(poi) {
    this.handleOpen();
    this.setState({ changeCenter: false });
    this.props.addPoiToTemplate(poi);
  }

  viewPoi(poi) {
    this.setState({ selectedPoi: poi, modal: true });
  }

  onRemoveItem(poi) {
    console.log('going to remove item with id: ', poi.id);
    //TODO dispatch to action
    this.props.removeFromTemplate(poi);
  }

  onMovePoiDown = (poi, e) => {
    let { selectedTemplate } = this.props.tourTemplates;
    let waypointList = selectedTemplate.waypointList;

    let index = waypointList.findIndex((item) => item.id === poi.id);
    console.log('found index: ' + index);

    let temp = waypointList[index];
    waypointList[index] = waypointList[index + 1];
    waypointList[index + 1] = temp;

    this.props.reorderPois(selectedTemplate);
  };

  onMovePoiUp = (poi, e) => {
    let { selectedTemplate } = this.props.tourTemplates;
    let waypointList = selectedTemplate.waypointList;

    let index = waypointList.findIndex((item) => item.id === poi.id);
    console.log('found index: ' + index);

    let temp = waypointList[index];
    waypointList[index] = waypointList[index - 1];
    waypointList[index - 1] = temp;

    this.props.reorderPois(selectedTemplate);
  };

  onRecalculateTour(tour) {
    console.log('onRecalculateTour called...', tour);
    const payload = { tour: tour, reordering: false };
    this.setState({ changeCenter: true });
    this.props.recalculateTour(payload);
  }

  onRecalculateTourReorder(tour) {
    console.log('onRecalculateTour called...', tour);
    const payload = { tour: tour, reordering: true };
    this.setState({ changeCenter: true });
    this.props.recalculateTour(payload);
  }

  onChangeFlagInactive(event) {
    const value = event.target.value;
    console.log('received new status -> inactive:', value);
    this.props.changeFlagInactive(value);
  }

  onChangeTransportationType(event) {
    const value = event.target.value;
    console.log('received new TransportationType', value);
    this.props.changeTransportationType(value);
  }

  clearError = () => {
    this.props.setError('');
  };

  toggle = () => {
    let currentModal = this.state.modal;
    this.setState({ modal: !currentModal });
  };

  handleChange = (event) => {};

  stabilizeTour() {
    this.props.stabilizeTour(this.props.tourTemplates.selectedTemplate.id);
  }

  render() {
    const { open } = this.state;
    const {
      selectedTemplate,
      fetching,
      showRecalculateButtons,
      showSaveButton,
    } = this.props.tourTemplates;

    let pois = this.props.pois.data;

    console.log(
      'REDUX tourTemplates fetching: ',
      this.props.tourTemplates.fetching
    );
    console.log('REDUX pois fetching: ', this.props.pois.fetching);

    return (
      <Container className="dashboard tour_dashboard">
        <Row>
          <Col>
            <div className="page_title_container">
              <h3 className="page_title">
                <Link to={`/tourTemplates/`}>Suggested Tours</Link>
                <ChevronRightIcon />
                {selectedTemplate.title}
              </h3>
              <h4 className="page_subtitle">
                In this section you can review or edit selected tour.
              </h4>
            </div>

            <Row className="tour_container">
              <Loader loading={fetching} className="full_page" />
              {!this.state.error && (
                <Error error={this.state.error} page={true} />
              )}
              {selectedTemplate.title && (
                <Col>
                  <Row>
                    <Col md={8}>
                      <Card>
                        <CardBody>
                          <Scrollbars
                            renderTrackHorizontal={(props) => (
                              <div {...props} className="track-horizontal" />
                            )}
                          >
                            <div className="d-flex justify-content-between top_buttons">
                              <div>
                                <Button
                                  // TODO add method to save the current template
                                  onClick={() => this.fetchTourTemplateFromDB()}
                                  className="discard_btn"
                                >
                                  Discard all changes
                                </Button>
                                <Button
                                  // TODO add method to save the current template
                                  onClick={() =>
                                    this.saveTourTemplate(selectedTemplate)
                                  }
                                  className="save_btn"
                                  disabled={!showSaveButton}
                                >
                                  Save Tour
                                </Button>
                              </div>
                              {showRecalculateButtons && (
                                <div className="d-flex justify-content-around">
                                  <Button
                                    color="success"
                                    onClick={() =>
                                      this.onRecalculateTourReorder(
                                        selectedTemplate
                                      )
                                    }
                                  >
                                    Calculate Route
                                    <span>
                                      keep only first and last POIs order
                                    </span>
                                  </Button>
                                  <Button
                                    color="success"
                                    onClick={() =>
                                      this.onRecalculateTour(selectedTemplate)
                                    }
                                  >
                                    Calculate Route
                                    <span>keep all POIs order</span>
                                  </Button>
                                </div>
                              )}
                            </div>

                            <GoogleMap
                              showMarker={true}
                              mapCenter={{ lat: 38.031876, lng: 23.78115 }}
                              waypoints={selectedTemplate.waypointList}
                              pathCoordinates={selectedTemplate.polylineDecoded}
                              showAllPois={open}
                              allPois={pois}
                              onClickPoi={this.onClickPoi}
                              addToTemplate={this.addToTemplate}
                              changeCenter={this.state.changeCenter}
                            ></GoogleMap>

                            <div className="select_container">
                              <SelectField
                                options={STATUS}
                                value={selectedTemplate.inactive}
                                id={'status'}
                                label="Status"
                                onChange={(event) =>
                                  this.onChangeFlagInactive(event)
                                }
                                editable={true}
                              />
                            </div>
                            <div className="select_container">
                              <SelectField
                                options={TRANSPORTATION}
                                value={selectedTemplate.transportationType}
                                id={'transportation'}
                                label="Transportation"
                                onChange={(event) =>
                                  this.onChangeTransportationType(event)
                                }
                                editable={true}
                              />
                            </div>
                          </Scrollbars>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={4}>
                      <div className="pois_container">
                        <div className="card_title_container d-flex justify-content-between align-items-center">
                          <h4 className="card_title">Pois List</h4>
                          <Button
                            type="button"
                            color="success"
                            size="sm"
                            outline={true}
                            onClick={() => this.handleOpen()}
                          >
                            Add POI
                          </Button>
                        </div>

                        <div className="pois_list_container">
                          <Scrollbars
                            renderTrackHorizontal={(props) => (
                              <div {...props} className="track-horizontal" />
                            )}
                          >
                            {selectedTemplate.waypointList.map((poi, index) => {
                              const start = index === 0;
                              const finish =
                                index ===
                                selectedTemplate.waypointList.length - 1;
                              return (
                                <div
                                  key={`waypoint-${poi.id}`}
                                  className="d-flex tour_pois"
                                >
                                  <div className="poi_line">
                                    <span>{index + 1}</span>
                                  </div>
                                  <div className="flex-fill">
                                    <Poi
                                      key={`tour-poi-${poi.id}`}
                                      start={start}
                                      finish={finish}
                                      poi={poi}
                                      onUpClick={() => this.onMovePoiUp(poi)}
                                      onDownClick={() =>
                                        this.onMovePoiDown(poi)
                                      }
                                      onRemoveClick={() =>
                                        this.onRemoveItem(poi)
                                      }
                                      editable={true}
                                    ></Poi>
                                  </div>
                                </div>
                              );
                            })}
                          </Scrollbars>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Customizer
          title="List of available POIs"
          caption="Select the POIs you want to include in the specific Tour template"
          open={open}
          className="container"
          handleOpen={() => this.handleOpen()}
        >
          <PoisList
            pois={this.props.pois}
            modal={this.state.modal}
            editable={false}
            selectedPoi={this.state.selectedPoi}
            selectedCategory={this.state.selectedCategory}
            toggle={() => this.toggle()}
            handleChange={(event) => this.handleChange(event)}
            handleFilterByCategory={(event) => this.handleCategoryFilter(event)}
            onAddClick={(poi) => this.addToTemplate(poi)}
            onViewClick={(poi) => this.viewPoi(poi)}
            xl={12}
            lg={12}
            md={12}
            s={12}
          ></PoisList>
        </Customizer>

        <Error
          error={this.props.tourTemplates.error}
          page={true}
          onErrorClose={() => this.clearError()}
        />
        {selectedTemplate.unstable && (
          <Message
            message={'This tour has been modified automatically.'}
            page={true}
            onMessageClose={() => this.stabilizeTour()}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tourTemplates: state.tourTemplates,
    title: state.title,
    pois: state.pois,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPois: (payload) => dispatch(getPois(payload)),
    getTourTemplate: (payload) => dispatch(getTourTemplate(payload)),
    recalculateTour: (payload) => dispatch(recalculateTour(payload)),
    removeFromTemplate: (payload) => dispatch(removePoiFromTemplate(payload)),
    reorderPois: () => dispatch(reorderPois()),
    saveTourTemplate: (payload) => dispatch(saveTourTemplate(payload)),
    addPoiToTemplate: (payload) => dispatch(addPoiToTemplate(payload)),
    changeTransportationType: (payload) =>
      dispatch(changeTransportationType(payload)),
    changeFlagInactive: (payload) => dispatch(changeFlagInactive(payload)),
    fetchAdditionalPois: (payload) => dispatch(fetchAdditionalPois(payload)),
    togglePoiInfoWindow: (payload) => dispatch(togglePoiInfoWindow(payload)),
    setError: (payload) => dispatch(setError(payload)),
    stabilizeTour: (payload) => dispatch(stabilizeTour(payload)),
    clearPois: (payload) => dispatch(clearPois(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TourTemplate)
);
