import {
  FETCH_POIS_SUCCESSFUL,
  POIS_LOADING,
  SAVE_POI_SUCCESSFUL,
  DELETE_POI_SUCCESSFUL,
  POIS_API_ERROR,
  TOGGLE_POI_INFO_WINDOW
} from '../../actions/pois/poisActions';

const initialState = {
  error: '',
  modal: false,
  fetching: false,
  data: [],
  saved: false,
  deleted: false,
  savedPoi: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case POIS_LOADING: {
      console.log('adding poi:', action.payload);
      return {
        ...state,
        error: '',
        fetching: true,
        saved: false,
        deleted: false
      };
    }

    case SAVE_POI_SUCCESSFUL: {
      return {
        ...state,
        error: '',
        fetching: false,
        saved: true,
        savedPoi: action.payload
      };
    }

    case DELETE_POI_SUCCESSFUL: {
      return {
        ...state,
        error: '',
        fetching: false,
        deleted: true
      };
    }

    case POIS_API_ERROR: {
      return {
        ...state,
        error: action.payload,
        fetching: false,
        saved: false
      };
    }

    case FETCH_POIS_SUCCESSFUL: {
      console.log('inside FETCH_POIS_SUCCESSFUL', action.payload);
      return {
        ...state,
        data: action.payload,
        error: '',
        fetching: false,
        saved: false
      };
    }

    case TOGGLE_POI_INFO_WINDOW: {
      console.log('inside TOGGLE_POI_INFO_WINDOW', action.payload);

      const id = action.payload;

      let newData = state.data.map(poi => {
        if (poi.id === id) {
          poi.isOpen = !poi.isOpen;
        }
        return poi;
      });

      return {
        ...state,
        data: newData,
        error: '',
        fetching: false,
        saved: false
      };
    }

    default:
      return state;
  }
}
