import {
  FETCH_STATISTICS_LOADING,
  FETCH_STATISTICS_SUCCESSFUL,
  FETCH_STATISTICS_ERROR
} from '../../actions/statistics/statisticsActions';

const initialState = {
  error: '',
  fetching: false,
  data: {},
  duration: 'week'
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATISTICS_LOADING: {
      return {
        ...state,
        error: '',
        fetching: true,
        data: {},
        duration: action.payload
      };
    }
    case FETCH_STATISTICS_ERROR: {
      return {
        ...state,
        error: action.payload,
        fetching: false,
        data: {}
      };
    }
    case FETCH_STATISTICS_SUCCESSFUL: {
      return {
        ...state,
        error: '',
        fetching: false,
        data: action.payload
      };
    }
    default:
      return state;
  }
}
