import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

import MainWrapper from '../MainWrapper/MainWrapper';
import NotFound404 from '../404/404';
import LogIn from '../LogIn/LogIn';
import Terms from '../Terms/Terms';
import Policy from '../Policy/Policy';
// import Register from '../Register/Register';
import WrappedRoutes from './WrappedRoutes';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <PrivateRoute path="/404" component={NotFound404} type="Private" />
        <PrivateRoute path="/log_in" component={LogIn} type="Public" />
        <PrivateRoute path="/terms" component={Terms} type="Public" />
        <PrivateRoute path="/policy" component={Policy} type="Public" />
        {/* <PrivateRoute path="/register" component={Register} type="Public" /> */}
        <PrivateRoute path="/" component={WrappedRoutes} type="Private" />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
