import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TourTemplate from '../../TourTemplate/TourTemplate';
import TourTemplates from '../../TourTemplates/TourTemplates';

export default () => (
  <Switch>
    <Route exact path="/" component={TourTemplates} />
    <Route exact path="/tourTemplates" component={TourTemplates} />
    <Route path="/tourTemplates/:id" component={TourTemplate} />
  </Switch>
);
