import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import authStorage from '../../services/authentication/authStorage';

const PrivateRoute = ({ component: Component, type, ...rest }) => {
  const isLoggedIn = authStorage.getAuth('auth');

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          type === 'Private' ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        ) : type === 'Public' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/log_in',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
